import { render, staticRenderFns } from "./nuevo.vue?vue&type=template&id=8fb5cc62&"
import script from "./nuevo.vue?vue&type=script&lang=js&"
export * from "./nuevo.vue?vue&type=script&lang=js&"
import style0 from "./nuevo.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VAppBar,VAppBarNavIcon,VAutocomplete,VBtn,VCol,VContainer,VFlex,VIcon,VLayout,VRow,VSelect,VTextField,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/Users/mbernet/Sites/venu-sanz-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8fb5cc62')) {
      api.createRecord('8fb5cc62', component.options)
    } else {
      api.reload('8fb5cc62', component.options)
    }
    module.hot.accept("./nuevo.vue?vue&type=template&id=8fb5cc62&", function () {
      api.rerender('8fb5cc62', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/grupo-ingredientes/nuevo.vue"
export default component.exports