<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-food-variant</v-icon>
      </div>

      <v-toolbar-title style="color:#84264f; font-weight:bold">
        Grupo de ingredientes
        <v-chip color="primary" class="ml-4">{{ totalNumIngredients$ }}</v-chip>
      </v-toolbar-title>
      <v-spacer />
      <v-btn medium color="primary" to="/grupo-ingredientes/nuevo">Añadir grupo de ingredientes</v-btn>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-container fluid>
      <v-dialog v-model="delDialog" max-width="290">
        <v-card>
          <v-card-title>Borrar</v-card-title>
          <v-card-text class="subtitle-1">Deseas borrar este grupo de ingredientes ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="delDialog = false">Cancelar</v-btn>
            <v-btn color="primary" @click="deleteGroupIngredient()">Sí</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="searchIngredients"
            @input="searchChange"
            prepend-inner-icon="mdi-magnify"
            background-color="red"
            label="Buscar ingrediente"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="ingredientOrderOptions"
            v-model="ingredientOrderdefaultSelected"
            item-text="label"
            item-value="value"
            label="Ordenar"
            dense
            outlined
            @change="selectSorting"
          ></v-select>
        </v-col>
      </v-row>

      <v-toolbar color="secondary" dark flat dense>
        <v-row>
          <v-col cols="12" sm="11">
            <div class="body-1">Nombre</div>
          </v-col>
          <v-col cols="12" sm="1">
            <div class="body-1"></div>
          </v-col>
        </v-row>
      </v-toolbar>

      <ItemList
        :items="ingredients$"
        :titleAction="{ type: 'EDIT_INGREDIENT' }"
        :actions="[
            { type: 'DELETE_INGREDIENT', icon: 'mdi-delete' }
          ]"
        type="ingredient"
        :dragAndDropActive="true"
        :totalItems="totalNumIngredients$"
        @actionTrigger="onActionTrigger($event)"
        @paginationChange="paginationChange($event)"
      />
    </v-container>
  </div>
</template>

<script>
import store from "../../store";
import ItemList from "@/components/itemList";
import { INGREDIENT_ORDER, TABLE_NUM_ITEMS } from "../../constants";

export default {
  data() {
    return {
      delDialog: false,
      itemIdToDelete: null,
      ingredientOrderOptions: INGREDIENT_ORDER,
      ingredientOrderdefaultSelected: INGREDIENT_ORDER[0].value,
      conditions: "",
      searchIngredients: null,
      sortBy: null
    };
  },
  components: { ItemList },
  computed: {
    ingredients$() {
      const ingredients = store.getters["ingredients/items"];

      return ingredients.filter(ingredient => {
        return ingredient.type === "COMPOUND";
      });
    },
    totalNumIngredients$() {
      return parseInt(store.getters["ingredients/totalItems"]);
    },
    error$() {
      return store.getters["ingredients/error"];
    }
  },
  methods: {
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    onActionTrigger({ event }) {
      switch (event.action) {
        case "DELETE_INGREDIENT":
          this.delDialog = true;
          this.itemIdToDelete = event.id;
          break;
        case "EDIT_INGREDIENT":
          this.$router.push(`/grupo-ingredientes/editar/${event.id}`);
          break;
      }
    },
    deleteGroupIngredient() {
      store
        .dispatch("ingredients/DeleteIngredient", { id: this.itemIdToDelete })
        .then(() => {
          this.delDialog = false;
        });
    },
    paginationChange(data) {
      this.search(
        data.event * TABLE_NUM_ITEMS,
        TABLE_NUM_ITEMS,
        this.searchIngredients,
        this.sortBy
      );
    },
    selectSorting(evt) {
      this.sortBy = evt;
      this.search(
        0,
        TABLE_NUM_ITEMS,
        this.searchIngredients,
        this.sortBy
      );
    },
    searchChange(evt) {
      this.searchIngredients = evt;
      this.search(
        0,
        TABLE_NUM_ITEMS,
        this.searchIngredients,
        this.sortBy
      );
    },
    search(offset, limit, search, sort) {
      let query = "%20type%20LIKE%20'%25COMPOUND%25'";

      if (search) {
        query += `%20AND%20name%20LIKE%20'%25${search}%25'`;
      }

      const order = sort === "date" ? `created DESC` : `name ASC`;

      store
        .dispatch("ingredients/LoadIngredients", {
          offset: offset,
          limit: limit,
          conditions: query,
          order: order
        })
        .then(() => {});
    },
    getAllergenNameById(id) {
      const allergenName = (
        this.allergens$.filter(allergen => allergen.id === id)[0] || {}
      ).name;
      return allergenName || null;
    }
  }
};
</script>

<style>
</style>