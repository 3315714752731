var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-food-variant")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [
            _c(
              "span",
              { staticStyle: { color: "#84264f", "font-weight": "bold" } },
              [_vm._v("Grupo de Ingredientes")]
            ),
            _vm._v(" / Nuevo grupo\n    ")
          ])
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.error$.message))
          ])
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-xs": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "pt-4": "", wrap: "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Nombre del grupo de ingredientes",
                      outlined: ""
                    },
                    model: {
                      value: _vm.group.name,
                      callback: function($$v) {
                        _vm.$set(_vm.group, "name", $$v)
                      },
                      expression: "group.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("div", { staticClass: "title mb-4" }, [
                    _vm._v("Añadir ingrediente")
                  ]),
                  _vm.errorAddingIngredientSimpleToCompound
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(
                          "Ingrediente no correcto, todos los campos son obligatorios"
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", xs: "6", sm: "4" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              label: "Ingredientes",
                              items: _vm.simpleIngredients$,
                              outlined: "",
                              "item-text": "name",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.currentIngredient.ingredientId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentIngredient,
                                  "ingredientId",
                                  $$v
                                )
                              },
                              expression: "currentIngredient.ingredientId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { xs: "6", sm: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "background-color": "white",
                              type: "number",
                              label: "Cantidad sugerida",
                              outlined: ""
                            },
                            model: {
                              value: _vm.currentIngredient.qty,
                              callback: function($$v) {
                                _vm.$set(_vm.currentIngredient, "qty", $$v)
                              },
                              expression: "currentIngredient.qty"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { xs: "12", sm: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.ingredientUnitsOptions,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Unidad sugerida",
                              outlined: ""
                            },
                            model: {
                              value: _vm.currentIngredient.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.currentIngredient, "unit", $$v)
                              },
                              expression: "currentIngredient.unit"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { xs: "12", sm: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "success", block: "" },
                              on: {
                                click: function($event) {
                                  return _vm.addSimple()
                                }
                              }
                            },
                            [_vm._v("Añadir")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.group.compoundIngredients &&
                  _vm.group.compoundIngredients.length
                    ? _c("div", { staticClass: "title mb-4" }, [
                        _vm._v("Listado de ingredientes")
                      ])
                    : _vm._e(),
                  _vm._l(_vm.group.compoundIngredients, function(
                    ingredient,
                    index
                  ) {
                    return _c(
                      "v-row",
                      { key: index },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "8" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { xs: "12", sm: "4" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "background-color": "white",
                                        type: "text",
                                        value: _vm.getSimpleIngredientNameById(
                                          ingredient.ingredientId
                                        ),
                                        label: "Unidad",
                                        placeholder: "Unidad",
                                        outlined: "",
                                        disabled: ""
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { xs: "12", sm: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "background-color": "white",
                                        type: "number",
                                        value: ingredient.qty,
                                        label: "Cantidad sugerida",
                                        placeholder: "Cantidad sugerida",
                                        outlined: "",
                                        disabled: ""
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { xs: "12", sm: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "background-color": "white",
                                        type: "text",
                                        value: _vm.ingredientUnitsOptions.find(
                                          function(unit) {
                                            return (
                                              unit.value === ingredient.unit
                                            )
                                          }
                                        ).label,
                                        label: "Unidad sugerida",
                                        placeholder: "Unidad sugerida",
                                        outlined: "",
                                        disabled: ""
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "2" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          depressed: "",
                                          color: "error",
                                          block: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteSimple(index)
                                          }
                                        }
                                      },
                                      [_vm._v("Borrar")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "12", sm: "3" } })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-white",
                  attrs: { color: "secondary", to: "/grupo-ingredientes" }
                },
                [
                  _c("v-icon", { attrs: { left: "", dark: "" } }, [
                    _vm._v("mdi-arrow-left")
                  ]),
                  _vm._v("Volver\n      ")
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { depressed: "", color: "success" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Guardar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }