<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-food-apple</v-icon>
      </div>
      <v-toolbar-title>
        <span style="color:#84264f; font-weight:bold">Grupo ingredientes</span> / Editar Grupo ingrediente
      </v-toolbar-title>
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-snackbar v-model="updatedSuccess">
      Grupo de ingredientes guardado correctamente
      <v-btn color="pink" timeout="4000" text @click="updatedSuccess = false">Cerrar</v-btn>
    </v-snackbar>

    <v-container fluid grid-list-xs>
      <v-layout pt-4 wrap align-center>
        <v-flex xs12>
          <v-text-field
            v-model="group.name"
            background-color="white"
            label="Nombre del grupo"
            placeholder="Nombre del grupo de ingredientes"
            outlined
            @change="save()"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <div class="title mb-4">Añadir ingrediente</div>
          <v-alert
            v-if="errorAddingIngredientSimpleToCompound"
            type="error"
          >Ingrediente no correcto, todos los campos son obligatorios</v-alert>
          <v-row>
            <v-col cols="12" xs="6" sm="4">
              <v-autocomplete
                label="Ingredientes"
                :items="simpleIngredients$"
                outlined
                item-text="name"
                item-value="id"
                v-model="currentIngredient.ingredientId"
              ></v-autocomplete>
            </v-col>
            <v-col xs="6" sm="3">
              <v-text-field
                background-color="white"
                type="number"
                v-model="currentIngredient.qty"
                label="Cantidad sugerida"
                outlined
              ></v-text-field>
            </v-col>
            <v-col xs="12" sm="3">
              <v-select
                :items="ingredientUnitsOptions"
                v-model="currentIngredient.unit"
                item-text="label"
                item-value="value"
                label="Unidad sugerida"
                outlined
              ></v-select>
            </v-col>
            <v-col xs="12" sm="2">
              <v-btn color="success" block @click="addSimple()">Añadir</v-btn>
            </v-col>
          </v-row>

          <div
            class="title mb-4"
            v-if="group.compoundIngredients && group.compoundIngredients.length"
          >Listado de ingredientes</div>

          <v-row v-for="(ingredient, index) in group.compoundIngredients" :key="index">
            <v-col cols="12" sm="8">
              <v-row>
                <v-col xs="12" sm="4">
                  <v-text-field
                    background-color="white"
                    type="text"
                    :value="getSimpleIngredientNameById(ingredient.ingredientId)"
                    label="Unidad"
                    placeholder="Unidad"
                    outlined
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col xs="12" sm="3">
                  <v-text-field
                    background-color="white"
                    type="number"
                    :value="ingredient.qty"
                    label="Cantidad sugerida"
                    placeholder="Cantidad sugerida"
                    outlined
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col xs="12" sm="3">
                  <v-text-field
                    background-color="white"
                    type="text"
                    :value="ingredientUnitsOptions.find(unit => unit.value === ingredient.unit).label"
                    label="Unidad sugerida"
                    placeholder="Unidad sugerida"
                    outlined
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-btn depressed color="error" block @click="deleteSimple(index)">Borrar</v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3"></v-col>
          </v-row>
        </v-flex>

        <v-btn class="text-white" color="secondary" to="/grupo-ingredientes">
          <v-icon left dark>mdi-arrow-left</v-icon>Volver
        </v-btn>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import store from "../../store/index";
import { UNITS_OF_MEASURE } from "../../constants";
import { getIngredient } from "../../services/ingredients";
import ingredientMapper from "../../mapper";

export default {
  data() {
    return {
      ingredientUnitsOptions: UNITS_OF_MEASURE.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),
      currentIngredient: {
        ingredientId: null,
        qty: null,
        unit: null
      },
      group: {
        id: null,
        name: null,
        type: "COMPOUND",
        compoundIngredients: [],
        includeInShoppingList: false
      },
      updatedSuccess: false,
      errorAddingIngredientSimpleToCompound: false
    };
  },
  async mounted() {
    const { data } = await getIngredient(this.$route.params.id);

    const ingredientMapped = ingredientMapper(data, "toUI");

    const group = { ...ingredientMapped };

    this.group.name = group.name;

    this.group.id = group.id;

    this.group.compoundIngredients = (group.sub_ingredients || []).map(
      ingredient => {
        return {
          id: group.id,
          ingredientId: ingredient.id,
          qty: ingredient.qty,
          unit: ingredient.units
        };
      }
    );
  },
  computed: {
    allergens$() {
      return store.getters["ingredients/allergens"];
    },
    simpleIngredients$() {
      return store.getters["ingredients/simpleIngredients"];
    },
    error$() {
      return store.getters["ingredients/error"];
    }
  },
  methods: {
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    save() {
      if (!this.group.name || !this.group.compoundIngredients.length) {
        alert("Grupo no valido.");
        return;
      }
      this.$store
        .dispatch("ingredients/CreateNewIngredient", {
          ingredient: this.group
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
    },
    cancel() {
      this.$router.back();
    },
    addSimple() {
      if (
        this.currentIngredient.unit &&
        this.currentIngredient.qty &&
        this.currentIngredient.ingredientId
      ) {
        this.errorAddingIngredientSimpleToCompound = false;

        const index = this.group.compoundIngredients.findIndex(
          e => e.ingredientId === this.currentIngredient.ingredientId
        );

        if (index === -1) {
          this.group.compoundIngredients.push({ ...this.currentIngredient });
        } else {
          this.group.compoundIngredients[index] = this.currentIngredient;
        }

        store
          .dispatch("ingredients/CreateNewIngredientCompound", {
            ingredient: { ...this.currentIngredient, id: this.group.id }
          })
          .then(() => {
            this.updatedSuccess = true;
            setTimeout(() => {
              this.updatedSuccess = false;
            }, 2000);
            
            this.currentIngredient = {
              ingredientId: null,
              qty: null,
              unit: null
            };
          })
          .catch(() => {});
      } else {
        this.errorAddingIngredientSimpleToCompound = true;
      }
    },
    deleteSimple(index) {
      store
        .dispatch("ingredients/DeleteIngredientCompound", {
          ingredientId: this.group.id,
          compoundId: this.group.compoundIngredients[index].ingredientId
        })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        });
      this.group.compoundIngredients.splice(index, 1);
    },
    getSimpleIngredientNameById(id) {
      const ingredient = this.simpleIngredients$.find(
        ingredient => ingredient.id === id
      );
      if (ingredient) {
        return ingredient.name;
      }
      return "";
    }
  }
};
</script>

<style>
.v-card {
  cursor: pointer;
}
.v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: white !important;
}
</style>
